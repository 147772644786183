import srraf from "srraf";
import lerp from "@/lib/animation/lerp";
import { qs, add, remove } from "@fluorescent/dom";

const selectors = {
  productMeta: ".product__meta",
};

const classes = {
  hasSticky: "product--has-sticky-scroll",
};

export default function (node) {
  const productMeta = qs(selectors.productMeta, node);

  node.style.setProperty("--product-meta-top", 0);

  // Init position vars
  // The previous scroll position of the page
  let previousScrollY = window.scrollY;
  // To keep track of the amount scrolled per event
  let currentScrollAmount = 0;
  // Height of the header bar, used for calculating position
  // Set in `_observeHeight()` when the --header-desktop-sticky-height var is set
  let headerHeight = 0;
  // The value to set the product meta `top` value to
  let metaTop = headerHeight;
  let metaTopPrevious = metaTop;
  // The height of the product meta container
  // Gets updated by a resize observer on the window and the meta container
  let metaHeight = productMeta.offsetHeight;
  // The height of the product meta container plus the height of the header
  let metaHeightWithHeader = metaHeight + headerHeight;
  // The max amount to set the meta `top` value
  // This is equal to the number of pixels
  // that the meta container is hidden by the viewport.
  // Gets updated by a resize observer on the window and the meta container
  let metaMaxTop = metaHeightWithHeader - window.innerHeight;

  // Whatch scroll updates
  const scroller = srraf(({ y }) => {
    _scrollHandler(y);
  });

  // Resize observer on the window and the product meta
  // Things like accordions can change the height of the meta container
  const resizeObserver = new ResizeObserver(_observeHeight);

  resizeObserver.observe(productMeta);
  resizeObserver.observe(document.documentElement);

  // Start the animation loop
  requestAnimationFrame(() => _updateMetaTopLoop());

  function _observeHeight() {
    metaHeight = productMeta.offsetHeight;
    headerHeight = parseInt(
      getComputedStyle(document.documentElement)
        .getPropertyValue("--header-desktop-sticky-height")
        .replace(/px/gi, "")
    );
    metaHeightWithHeader = metaHeight + headerHeight;
    metaMaxTop = metaHeightWithHeader - window.innerHeight;

    // Check if the product meta container is taller than the viewport
    // and section container has room for the meta to scroll.
    // The product meta could be taller than the images
    // so it won't have room to scroll.
    if (
      metaHeightWithHeader > window.innerHeight &&
      node.offsetHeight > metaHeightWithHeader
    ) {
      add(node, classes.hasSticky);
      _scrollHandler(window.scrollY);
    } else {
      remove(node, classes.hasSticky);
    }
  }

  function _scrollHandler(y) {
    currentScrollAmount = previousScrollY - y;

    // The offset based on how far the page has been scrolled from last event
    const currentScrollOffset = metaTop + currentScrollAmount;
    // The max top value while scrolling up
    const topMax = headerHeight;
    // The max top value while scrolling down
    const bottomMax = -metaMaxTop + headerHeight - 40;

    // Calculate the current top value based on the currentScrollOffset value
    // in the range of topMax and bottomMax.
    metaTop = Math.max(bottomMax, Math.min(currentScrollOffset, topMax));

    // Update the previous scroll position for next time.
    previousScrollY = y;
  }

  // This is an endless RAF loop used to update the top position CSS var.
  // We're using this with a LERP function to smooth out the position updating
  // instead of having large jumps while scrolling fast.
  function _updateMetaTopLoop() {
    // We want to continue to update the top var until fully into the stopped position
    if (metaTop !== metaTopPrevious) {
      metaTopPrevious = lerp(metaTopPrevious, metaTop, 0.5);
      node.style.setProperty("--product-meta-top", `${metaTopPrevious}px`);
    }
    requestAnimationFrame(() => _updateMetaTopLoop());
  }

  function destroy() {
    scroller?.scroller.destroy();
    resizeObserver?.disconnect();
  }

  return { destroy };
}
