import Delegate from "ftdomdelegate";
import { remove, qs, add } from "@fluorescent/dom";

import { section, cart } from "@/glow";
import { on } from "@/glow/events";
import { makeRequest } from "@/lib/xhr";
import QuantityButtons from "@/lib/quantity-buttons";
import CartNoteToggle from "@/lib/cart-note-toggle";

const selectors = {
  cartError: ".cart__form-item-error",
  cartNoteTrigger: "[data-order-note-trigger]",
  cartUpdateButton: ".cart__update",
  quantityInput: ".cart .quantity-input__input",
  quantityItem: "[data-input-item]",
};

const classes = {
  updatingQuantity: "has-quantity-update",
  removed: "is-removed",
};

section("cart", {
  onLoad() {
    const cartNoteTrigger = qs(selectors.cartNoteTrigger, this.container);

    if (cartNoteTrigger) this.cartNoteToggle = CartNoteToggle(this.container);
    this.quantityButtons = QuantityButtons(this.container);

    // Events are all on events trigger by other components / functions
    this.events = [
      on("cart:updated", () => this.refreshCart()),
      on("cart:error", (_, { id, errorMessage }) => {
        this.handleErrorMessage(id, errorMessage);
      }),
      on(
        ["quantity-update:subtract", "quantity-update:add"],
        (_, { itemId }) => {
          this.handleQuantityUpdate(itemId);
        }
      ),
      on("quantity-update:remove", (_, { itemId }) => {
        this.handleItemRemoval(itemId);
      }),
    ];

    // Delegate handles all click events due to rendering different content
    // within cart
    this.delegate = new Delegate(this.container);
    this.delegate.on("change", selectors.quantityInput, e =>
      this.handleQuantityInputChange(e)
    );
  },

  refreshCart() {
    const url = `${theme.routes.cart.base}?section_id=main-cart`;

    makeRequest("GET", url).then(response => {
      let container = document.createElement("div");
      container.innerHTML = response;

      this.container.innerHTML = container.innerHTML;

      if (window.Shopify?.StorefrontExpressButtons) {
        window.Shopify.StorefrontExpressButtons.initialize();
      }
    });
  },

  handleErrorMessage(itemId) {
    const item = qs(`[data-id="${itemId}"]`, this.container);
    remove(qs(selectors.cartError, item), "hidden");
    remove(item, classes.updatingQuantity);
  },

  handleQuantityInputChange({ target }) {
    const item = target.closest(selectors.quantityItem);
    const itemId = item.dataset.id;

    cart.updateItem(itemId, target.value);
    this.handleQuantityUpdate(itemId);
  },

  handleQuantityUpdate(itemId) {
    const item = qs(`[data-id="${itemId}"]`, this.container);
    add(item, classes.updatingQuantity);
  },

  handleItemRemoval(itemId) {
    const item = qs(`[data-id="${itemId}"]`, this.container);
    add(item, classes.removed);
    add(item, classes.updatingQuantity);
  },

  onUnload() {
    this.events.forEach(unsubscribe => unsubscribe());
    this.quantityButtons.unload();
    this.cartNoteToggle?.unload();
  },
});
