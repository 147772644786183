import { section } from "@/glow";
import { add, contains, listen, qs, qsa, remove } from "@fluorescent/dom";
import getMediaQuery from "@/lib/media-queries";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import srraf from "srraf";
import { emit } from "@/glow/events";
import {
  animateShoppableImage,
  animateShoppableFeature,
  shouldAnimate,
} from "@/lib/animation";
import atBreakpointChange from "@/lib/at-breakpoint-change";

const selectors = {
  hotspotWrappers: ".shoppable-item",
  hotspots: ".shoppable-item__hotspot",
  productCard: ".shoppable-item__product-card",
  closeButtons: "[data-shoppable-item-close]",
  mobileDrawer: ".shoppable-feature-mobile-drawer",
  desktopSliderContainer: ".shoppable-feature__secondary-content",
  slider: ".swiper",
  slide: ".swiper-slide",
  sliderPagination: ".swiper-pagination",
  sliderImages: ".product-card-mini__image img",
  imageContainer: ".shoppable__image-container",
  sliderNavPrev: ".slider-nav-button-prev",
  sliderNavNext: ".slider-nav-button-next",
  drawerBackground: ".mobile-drawer__overlay",
  drawerCloseButton: ".mobile-drawer__close",
  quickViewTrigger: "[data-quick-view-trigger]",
  wash: "[data-shoppable-wash]",
};

const classes = {
  animating: "shoppable-item--animating",
  unset: "shoppable-item--position-unset",
  hidden: "hidden",
  active: "active",
  drawerActive: "active",
  pulse: "shoppable-item__hotspot--pulse",
};

const sliderTypes = {
  Desktop: "desktop",
  Mobile: "mobile",
};

section("shoppable", {
  onLoad() {
    this.imageContainer = qs(selectors.imageContainer, this.container);
    this.showHotspotCards = this.container.dataset.showHotspotCards === "true";
    this.hasCarousel = this.container.dataset.hasCarousel === "true";
    this.productCards = qsa(selectors.productCard, this.container);
    this.hotspotContainers = qsa(selectors.hotspotWrappers, this.container);
    this.hotspots = qsa(selectors.hotspots, this.container);
    this.wash = qs(selectors.wash, this.container);

    const closeButtons = qsa(selectors.closeButtons, this.container);

    // Self terminating mouseenter events
    this.hotspotEvents = this.hotspots.map(hotspot => {
      return {
        element: hotspot,
        event: listen(hotspot, "mouseenter", e => {
          remove(e.currentTarget.parentNode, classes.animating);
          this.hotspotEvents.find(o => o.element === hotspot).event();
        }),
      };
    });

    this.events = [
      listen(this.hotspots, "click", e => this._hotspotClickHandler(e)),
      listen(closeButtons, "click", () => this._closeAll()),
      listen(this.container, "keydown", ({ keyCode }) => {
        if (keyCode === 27) this._closeAll();
      }),
      listen(qsa(selectors.quickViewTrigger, this.container), "click", e => {
        const { productUrl } = e.target.dataset;
        if (!productUrl) return;

        emit("quick-view:open", null, {
          productUrl: productUrl,
        });
        if (window.matchMedia(getMediaQuery("below-960")).matches) {
          this._closeDrawer();
        }
      }),
    ];

    this.breakPointHandler = atBreakpointChange(960, () => {
      this._closeAll();
    });

    if (this.hasCarousel) {
      this._setupDrawer();
      this._createOrRecreateSlider();
      this.mobileDrawer = qs(selectors.mobileDrawer, this.container);
      this.widthWatcher = srraf(({ vw, pvw }) => {
        const wasAboveBreakpoint = pvw >= 960,
          isAboveBreakpoint = vw >= 960;
        if (wasAboveBreakpoint !== isAboveBreakpoint) {
          this._createOrRecreateSlider();
        }
      });
      if (shouldAnimate(this.container)) {
        this.animateShoppableFeature = animateShoppableFeature(this.container);
      }
    } else {
      this.events.push(
        listen(document, "click", e => this._clickOutsideHandler(e))
      );
    }

    if (this.showHotspotCards) {
      // Show the first hotspot as active if showing as card and above drawer
      // showing screen width
      if (
        window.matchMedia(getMediaQuery("above-960")).matches &&
        this.hotspots.length
      ) {
        this._activateHotspot(0);
      }

      // Predefine product card dimensions
      this.productCards.forEach(card => this._setCardDemensions(card));
      if (shouldAnimate(this.container)) {
        this.animateShoppableImage = animateShoppableImage(this.container);
      }
    }

    this._initPulseLoop();
  },

  _initPulseLoop() {
    const hotspots = qsa(selectors.hotspots, this.container);
    let pulseIndex = 0;

    this.pulseInterval = setInterval(() => {
      remove(hotspots, classes.pulse);
      setTimeout(() => {
        add(hotspots[pulseIndex], classes.pulse);
        pulseIndex++;
        if (pulseIndex >= hotspots.length) {
          pulseIndex = 0;
        }
      }, 0);
    }, 3000);
  },

  _pulseLoop() {},

  _createOrRecreateSlider() {
    // This creates or recreates either a mobile or desktop slider as necessary
    const sliderType = window.matchMedia(getMediaQuery("above-960")).matches
      ? sliderTypes.Desktop
      : sliderTypes.Mobile;

    if (this.sliderType !== sliderType) {
      this.sliderType = sliderType;
      this.swiper?.destroy();
      this.sliderInitalized = false;
      const sliderContainerSelector =
        sliderType === sliderTypes.Desktop
          ? selectors.desktopSliderContainer
          : selectors.mobileDrawer;

      this.sliderContainer = qs(sliderContainerSelector, this.container);
      this.slider = qs(selectors.slider, this.sliderContainer);
      this.slides = qsa(selectors.slide, this.sliderContainer);
      this.sliderPagination = qs(
        selectors.sliderPagination,
        this.sliderContainer
      );
      this.sliderNavNext = qs(selectors.sliderNavNext, this.sliderContainer);
      this.sliderNavPrev = qs(selectors.sliderNavPrev, this.sliderContainer);
      this.sliderImages = qsa(selectors.sliderImages, this.sliderContainer);

      if (this.slides.length < 2) {
        return;
      }
      const _this = this;
      import(flu.chunks.swiper).then(({ Swiper, Navigation, Pagination }) => {
        this.swiper = new Swiper(this.slider, {
          modules: [Navigation, Pagination],
          grabCursor: window.matchMedia(getMediaQuery("below-960")).matches,
          slidesPerView: 1,
          watchSlidesProgress: true,
          loop: true,
          navigation: {
            nextEl: this.sliderNavNext,
            prevEl: this.sliderNavPrev,
          },
          pagination: {
            el: this.sliderPagination,
            type: "fraction",
          },
          on: {
            sliderFirstMove: function () {
              _this.sliderHasBeenInteractedWith = true;
            },
            activeIndexChange: function (swiper) {
              const index = swiper.realIndex;
              _this.sliderInitalized && _this._indicateActiveHotspot(index);
            },
            afterInit: function () {
              _this.sliderInitalized = true;
              _this.sliderImages?.forEach(image =>
                image.setAttribute("loading", "eager")
              );
              if (_this.sliderType !== sliderTypes.Mobile) {
                _this._indicateActiveHotspot(0);
              }
            },
            slideChangeTransitionEnd() {
              const slideEls = this.slides;
              setTimeout(function () {
                slideEls.forEach(slide => {
                  slide.toggleAttribute(
                    "inert",
                    !slide.classList.contains("swiper-slide-active")
                  );
                });
              }, 50);
            },
          },
        });
      });
    }
  },

  _indicateActiveHotspot(index) {
    this.hotspotContainers.forEach(spot => remove(spot, classes.active));
    const dotWrapper = qs(
      `.shoppable-item[data-index='${index}']`,
      this.container
    );
    add(dotWrapper, classes.active);
  },

  _activateHotspot(index) {
    const wrapper = qs(
      `.shoppable-item[data-index='${index}']`,
      this.container
    );
    const card = qs(selectors.productCard, wrapper);
    const hotspot = qs(selectors.hotspots, wrapper);

    if (!card) {
      if (this.swiper) {
        const isMobileSwiper = this.sliderType === sliderTypes.Mobile;
        this.swiper.slideToLoop(index, isMobileSwiper ? 0 : undefined);
        if (isMobileSwiper) {
          this._openDrawer();
        }
      }
      return;
    }

    if (contains(card, "hidden")) {
      this._closeAll();
      card.setAttribute("aria-hidden", false);
      this._setCardDemensions(card);
      remove(card, classes.hidden);

      // When a slider is involved, updating the slider's active
      // slide will then trigger an update on the hotspot, but
      // when there is no slider involved we will do that directly
      if (!this.swiper) {
        this._indicateActiveHotspot(index);
      }

      if (window.matchMedia(getMediaQuery("below-960")).matches) {
        this._showWash();
      }
    } else {
      card.setAttribute("aria-hidden", true);
      add(card, classes.hidden);
      remove(wrapper, classes.active);
    }
  },

  _setCardDemensions(card) {
    const cardHeight = card.offsetHeight;
    const cardWidth = card.offsetWidth;
    card.style.setProperty("--card-height", cardHeight + "px");
    card.style.setProperty("--card-width", cardWidth + "px");
  },

  _setupDrawer() {
    // TODO: should this and open/close drawer functions be moved to their own file?

    const drawerBackground = qs(selectors.drawerBackground, this.container);
    const drawerCloseButton = qs(selectors.drawerCloseButton, this.container);

    this.events.push(
      listen(drawerBackground, "click", () => this._closeDrawer())
    );
    this.events.push(
      listen(drawerCloseButton, "click", () => this._closeDrawer())
    );
  },

  _openDrawer() {
    add(this.mobileDrawer, classes.drawerActive);
    disableBodyScroll(this.mobileDrawer);
    this._showWash();
  },

  _showWash() {
    add(this.wash, classes.active);
  },

  _closeDrawer() {
    if (this.mobileDrawer) {
      remove(this.mobileDrawer, classes.drawerActive);
      enableBodyScroll(this.mobileDrawer);
    }
    this._closeAll();
  },

  _hotspotClickHandler(e) {
    const wrapper = e.currentTarget.parentNode.parentNode;
    const hotspotIndex = parseInt(wrapper.dataset.index, 10);
    this._activateHotspot(hotspotIndex);
  },

  _clickOutsideHandler(e) {
    if (
      !e.target.closest(selectors.productCard) &&
      !contains(e.target, "shoppable-item__hotspot")
    ) {
      this._closeAll();
    }
  },

  _closeAll() {
    this.productCards.forEach(card => {
      add(card, classes.hidden);
      card.setAttribute("aria-hidden", true);
    });
    this.hotspotContainers.forEach(spot => remove(spot, classes.active));
    remove(this.wash, classes.active);
  },

  onBlockDeselect() {
    this._closeAll();
  },

  onBlockSelect({ target: el }) {
    const index = parseInt(el.dataset.index, 10);
    if (this.swiper) {
      this.swiper.slideToLoop(index);
    } else {
      this._activateHotspot(index);
    }
  },

  onUnload() {
    this.swiper?.destroy();
    this.widthWatcher?.destroy();
    this.events.forEach(unsubscribe => unsubscribe());
    this.animateShoppableImage?.destroy();
    this.animateShoppableFeature?.destroy();
    this.pulseInterval && clearInterval(this.pulseInterval);
  },
});
