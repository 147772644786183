import { qs, qsa } from "@fluorescent/dom";

import ProductItem from "@/lib/product-item";
import getMediaQuery from "@/lib/media-queries";
import atBreakpointChange from "@/lib/at-breakpoint-change";
import Carousel from "@/lib/carousel";

const selectors = {
  featuredProducts: "[data-featured-products]",
  leftSideMobileFeaturedProducts:
    ".left-side-blocks.for-mobile [data-featured-products]",
};

const featuredProducts = node => {
  const featuredProducts = qsa(selectors.featuredProducts, node);

  if (!featuredProducts.length) return;

  const productItems = featuredProducts.forEach(productContainer =>
    ProductItem(productContainer)
  );

  const enableMobileSwiper =
    featuredProducts[0].dataset.enableMobileSwiper === "true";
  let mobileSwiper;
  let mobileFeaturedProducts;

  if (enableMobileSwiper) {
    // Left column blocks are rendered twice to keep correct
    // ordering with right column blocks on mobile. Here we
    // target the mobile left version if it exists as it requires
    // the potential mobile swiper only.
    mobileFeaturedProducts =
      qs(selectors.leftSideMobileFeaturedProducts, node) ||
      qs(selectors.featuredProducts, node);

    if (window.matchMedia(getMediaQuery("below-720")).matches) {
      _initMobileSwiper();
    }

    atBreakpointChange(720, () => {
      if (window.matchMedia(getMediaQuery("below-720")).matches) {
        _initMobileSwiper();
      } else {
        mobileSwiper?.destroy();
      }
    });
  }

  function _initMobileSwiper() {
    mobileSwiper = Carousel(mobileFeaturedProducts, {
      slidesPerView: 2.1,
      spaceBetween: 12,
    });
  }

  function unload() {
    productItems.forEach(item => item.unload());
    mobileSwiper?.destroy();
  }

  return { unload };
};

export default featuredProducts;
