import { getUrlWithVariant } from "@shopify/theme-product-form";
import isMobile from "is-mobile";
import { getVariantFromId } from "@shopify/theme-product";
import scrollContainer from "@/lib/scroll-container";
import getMediaQuery from "@/lib/media-queries";
import atBreakpointChange from "@/lib/at-breakpoint-change";
import SocialShare from "@/lib/social-share";

import { add, listen, qs, qsa, remove, toggle } from "@fluorescent/dom";
import { cart, getProduct } from "@/glow";
import { emit } from "@/glow/events";

import accordions from "@/lib/accordions";
import Media from "@/lib/media";
import ProductForm from "@/lib/product-form";
import { switchImage } from "@/utils";
import {
  quantityInput,
  informationPopup,
  moreMedia,
  updatePrices,
  updateSku,
  updateBuyButton,
  reviewsHandler,
  optionButtons,
  inventoryCounter,
  stickyScroll,
  featuredProducts,
  variantAvailability,
} from "@/lib/product";
import { updateUnitPrices } from "@/lib/unit-pricing";
import storeAvailability from "@/lib/store-availability";
import { wrapIframes, wrapTables } from "@/lib/rte";
import dispatchCustomEvent from "@/lib/dispatch-custom-event";

const selectors = {
  form: "[data-product-form]",
  addToCart: "[data-add-to-cart]",
  variantSelect: "[data-variant-select]",
  optionById: id => `[value='${id}']`,
  thumbs: "[data-product-thumbnails]",
  thumb: "[data-product-thumbnail]",
  storeAvailability: "[data-store-availability-container]",
  quantityError: "[data-quantity-error]",
  productOption: ".product__option",
  optionLabelValue: "[data-selected-value-for-option]",
  displayedDiscount: "[data-discount-display]",
  displayedDiscountByVariantId: id =>
    `[variant-discount-display][variant-id="${id}"]`,
  nonSprRatingCountLink: ".product__rating-count-potential-link",
  photosMobile: ".product__media-container.below-mobile",
  photosDesktop: ".product__media-container.above-mobile",
  priceWrapper: ".product__price",
  quickCart: ".quick-cart",
  purchaseConfirmation: ".purchase-confirmation-popup",
  productReviews: "#shopify-product-reviews",
  customOptionInputs: "[data-custom-option-input]",
  customOptionInputTargetsById: id => `[data-custom-option-target='${id}']`,
};

class Product {
  constructor(node) {
    this.container = node;
    this.accordions = [];

    const {
      isQuickView,
      isFullProduct,
      isFeaturedProduct,
      enableStickyProductDetails,
    } = this.container.dataset;
    this.isQuickView = isQuickView;
    this.isFullProduct = isFullProduct;
    this.isFeaturedProduct = isFeaturedProduct;

    this.formElement = qs(selectors.form, this.container);
    this.quantityError = qs(selectors.quantityError, this.container);

    this.displayedDiscount = qs(selectors.displayedDiscount, this.container);

    this.viewInYourSpace = qs("[data-in-your-space]", this.container);
    this.viewInYourSpace && toggle(this.viewInYourSpace, "visible", isMobile());

    this.photosDesktop = qs(selectors.photosDesktop, this.container);

    this.breakPointHandler = atBreakpointChange(960, () => {
      if (window.matchMedia(getMediaQuery("below-960")).matches) {
        this._initPhotoCarousel();
      } else {
        this.mobileSwiper?.destroy();
      }
    });

    if (window.matchMedia(getMediaQuery("below-960")).matches) {
      this._initPhotoCarousel();
    }

    this.productThumbnails = qs(selectors.thumbs, this.container);
    this.productThumbnailItems = qsa(selectors.thumb, this.container);

    if (this.productThumbnails) {
      this.productThumbnailsScroller = scrollContainer(this.productThumbnails);
    }

    this.moreMedia = moreMedia(this.container);

    // Handle Surface pickup
    this.storeAvailabilityContainer = qs(
      selectors.storeAvailability,
      this.container
    );
    this.availability = null;

    // Handle Shopify Product Reviews if they exist as a product block
    this.reviewsHandler = reviewsHandler(
      qs(selectors.productReviews, this.container),
      this.container
    );

    // // non-SPR rating display
    let nonSprRatingCount = qs(selectors.nonSprRatingCountLink, this.container);

    if (nonSprRatingCount && !qs(selectors.productReviews, document)) {
      // The rating count links to "#shopify-product-reviews" but
      // if that block doesn't exist we should remove the link
      nonSprRatingCount.removeAttribute("href");
    }

    if (this.formElement) {
      const { productHandle, currentProductId } = this.formElement.dataset;
      const product = getProduct(productHandle);
      product(data => {
        const variant = getVariantFromId(data, parseInt(currentProductId));

        if (this.storeAvailabilityContainer && variant) {
          this.availability = storeAvailability(
            this.storeAvailabilityContainer,
            data,
            variant
          );
        }

        this.productForm = ProductForm(this.container, this.formElement, data, {
          onOptionChange: e => this.onOptionChange(e),
          onFormSubmit: e => this.onFormSubmit(e),
          onQuantityChange: e => this.onQuantityChange(e),
        });

        const productInventoryJson = qs(
          "[data-product-inventory-json]",
          this.container
        );

        if (productInventoryJson) {
          const jsonData = JSON.parse(productInventoryJson.innerHTML);
          const variantsInventories = jsonData.inventory;

          if (variantsInventories) {
            const config = {
              id: variant.id,
              variantsInventories,
            };
            this.inventoryCounter = inventoryCounter(this.container, config);
          }
        }
      });
    }

    this.quantityInput = quantityInput(this.container);
    this.customOptionInputs = qsa(selectors.customOptionInputs, this.container);
    this.socialButtons = qsa("[data-social-share]", this.container);
    this.featuredProducts = featuredProducts(this.container);

    if (enableStickyProductDetails === "true" && !isMobile()) {
      this.stickyScroll = stickyScroll(this.container);
    }

    const accordionElements = qsa(".accordion", this.container);
    accordionElements.forEach(accordion => {
      const accordionOpen = accordion.classList.contains("accordion--open");
      this.accordions.push(accordions(accordion, { firstOpen: accordionOpen }));

      const accordionParent = accordion.parentElement;
      if (
        accordionParent.classList.contains("rte--product") &&
        !accordionParent.classList.contains("accordion accordion--product")
      ) {
        accordion.classList.add("rte--product", "accordion--product");
      }
    });

    this.mediaContainers = Media(
      qs(".product__media-container.above-mobile", this.container)
    );
    this.mediaContainersMobile = Media(
      qs(".product__media-container.below-mobile", this.container)
    );

    this.optionButtons = optionButtons(
      qsa("[data-option-buttons]", this.container)
    );

    this.informationPopup = informationPopup(this.container);

    const productDescriptionWrapper = qs(
      ".product__description",
      this.container
    );

    if (productDescriptionWrapper) {
      wrapIframes(qsa("iframe", productDescriptionWrapper));
      wrapTables(qsa("table", productDescriptionWrapper));
    }

    const socialShareContainer = qs(".social-share", this.container);

    if (socialShareContainer) {
      this.socialShare = SocialShare(socialShareContainer);
    }
    this._initEvents();

    // Handle dynamic variant options
    this.variantAvailability = variantAvailability(this.container);
  }

  _initEvents() {
    this.events = [
      listen(this.productThumbnailItems, "click", e => {
        e.preventDefault();
        const {
          currentTarget: { dataset },
        } = e;

        this.productThumbnailItems.forEach(thumb => remove(thumb, "active"));
        add(e.currentTarget, "active");

        switchImage(
          this.photosDesktop,
          dataset.thumbnailId,
          this.viewInYourSpace
        );
      }),
    ];

    // Adds listeners for each custom option, to sync input changes
    if (this.customOptionInputs) {
      this.customOptionInputs.forEach(input => {
        const id = input.dataset.customOptionInput;
        const target = qs(
          selectors.customOptionInputTargetsById(id),
          this.container
        );

        this.events.push(
          listen(input, "change", e => {
            // Update the hidden input within the form, per type
            if (e.target.type === "checkbox") {
              target.checked = e.target.checked;
            } else {
              target.value = e.target.value;
            }
          })
        );
      });
    }
  }

  _initPhotoCarousel() {
    let swiperWrapper = qs(selectors.photosMobile, this.container);

    import(flu.chunks.swiper).then(({ Swiper, Pagination }) => {
      this.mobileSwiper = new Swiper(swiperWrapper, {
        modules: [Pagination],
        slidesPerView: 1,
        spaceBetween: 4,
        grabCursor: true,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
        watchSlidesProgress: true,
      });
      this.mobileSwiper.on("slideChange", evt => {
        if (this.viewInYourSpace) {
          const activeSlide = evt.slides[evt.activeIndex];
          if (activeSlide.dataset.mediaType === "model") {
            this.viewInYourSpace.setAttribute(
              "data-shopify-model3d-id",
              activeSlide.dataset.mediaItemId
            );
          }
        }

        this.mediaContainersMobile &&
          this.mediaContainersMobile.pauseActiveMedia();
      });
    });
  }

  // When the user changes a product option
  onOptionChange({ dataset: { variant }, srcElement }) {
    // Update option label
    const optionParentWrapper = srcElement.closest(selectors.productOption);
    const optionLabel = qs(selectors.optionLabelValue, optionParentWrapper);

    if (optionLabel) {
      optionLabel.textContent = srcElement.value;
    }

    const buyButton = qs(selectors.addToCart, this.container);

    const priceWrapper = qs(selectors.priceWrapper, this.container);

    toggle(priceWrapper, "hide", !variant);

    // Update prices to reflect selected variant
    updatePrices(this.container, variant);

    // Update buy button
    updateBuyButton(buyButton, variant);

    // Update unit pricing
    updateUnitPrices(this.container, variant);

    // Update sku
    updateSku(this.container, variant);

    // Update product availability content
    this.availability && this.availability.update(variant);

    // Update displayed discount
    if (this.displayedDiscount) {
      const newDiscountEl =
        variant &&
        qs(selectors.displayedDiscountByVariantId(variant.id), this.container);

      if (variant && newDiscountEl) {
        this.displayedDiscount.textContent = newDiscountEl.textContent;
      } else {
        this.displayedDiscount.textContent = "";
      }
    }

    this.inventoryCounter && this.inventoryCounter.update(variant);

    dispatchCustomEvent("product:variant-change", { variant: variant });

    if (!variant) {
      updateBuyButton(qs("[data-add-to-cart]", this.container), false);

      this.availability && this.availability.unload();
      return;
    }

    // Update URL with selected variant
    const url = getUrlWithVariant(window.location.href, variant.id);
    window.history.replaceState({ path: url }, "", url);

    // We need to set the id input manually so the Dynamic Checkout Button works
    const selectedVariantOpt = qs(
      `${selectors.variantSelect} ${selectors.optionById(variant.id)}`,
      this.container
    );
    selectedVariantOpt.selected = true;

    // We need to dispatch an event so Shopify pay knows the form has changed
    this.formElement.dispatchEvent(new Event("change"));

    // Update selected variant image and thumb
    if (variant.featured_media) {
      if (this.isFullProduct) {
        if (this.mobileSwiper) {
          const slidesWrap = this.mobileSwiper.el;
          const targetSlide = qs(
            `[data-media-item-id="${variant.featured_media.id}"]`,
            slidesWrap
          );

          if (targetSlide) {
            const targetSlideIndex = [
              ...targetSlide.parentElement.children,
            ].indexOf(targetSlide);
            this.mobileSwiper.slideTo(targetSlideIndex);
          }
        } else {
          const imagesWrap = qs(".product__media-container.above-mobile");

          if (imagesWrap.dataset.galleryStyle === "thumbnails") {
            switchImage(
              this.photosDesktop,
              variant.featured_media.id,
              this.viewInYourSpace
            );

            const thumb = qs(
              `[data-thumbnail-id="${variant.featured_media.id}"]`,
              this.photosDesktop
            );

            this.productThumbnailItems.forEach(thumb =>
              remove(thumb, "active")
            );
            add(thumb, "active");
          } else {
            const targetImage = qs(
              `.product__media-container.above-mobile [data-media-id="${variant.featured_media.id}"]`
            );
            if (this.isFeaturedProduct) {
              this.switchCurrentImage(variant.featured_media.id);
            } else {
              targetImage.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "nearest",
              });
            }
          }
        }
      } else {
        this.switchCurrentImage(variant.featured_media.id);
      }
    }
  }

  switchCurrentImage(id) {
    const imagesWraps = qsa(".product__media", this.container);
    imagesWraps.forEach(imagesWrap => switchImage(imagesWrap, id));
  }

  // When user updates quantity
  onQuantityChange({ dataset: { variant, quantity } }) {
    // Adjust the hidden quantity input within the form
    const quantityInputs = [...qsa('[name="quantity"]', this.formElement)];
    quantityInputs.forEach(quantityInput => {
      quantityInput.value = quantity;
    });

    dispatchCustomEvent("product:quantity-update", {
      quantity: quantity,
      variant: variant,
    });
  }

  // When user submits the product form
  onFormSubmit(e) {
    const purchaseConfirmation = qs(selectors.purchaseConfirmation, document);
    const quickCart = qs(selectors.quickCart, document);
    const isQuickViewForm = Boolean(e.target.closest(".quick-product"));

    // if quick cart and confirmation popup are enable submit form
    if (!purchaseConfirmation && !quickCart && !isQuickViewForm) return;

    e.preventDefault();

    add(this.quantityError, "hidden");

    const button = qs(selectors.addToCart, this.container);
    add(button, "loading");
    cart
      .addItem(this.formElement)
      .then(({ item }) => {
        remove(button, "loading");
        if (purchaseConfirmation && !isMobile()) {
          emit("confirmation-popup:open", null, { product: item });
        } else {
          emit("quick-cart:open");
        }
        dispatchCustomEvent("cart:item-added", { product: item });
      })
      .catch(error => {
        cart.get(); // update local cart data
        if (error && error.message) {
          this.quantityError.innerText = error.message;
        } else {
          this.quantityError.innerText = this.quantityErorr.getAttribute(
            "data-fallback-error-message"
          );
        }
        remove(this.quantityError, "hidden");
        const button = qs(selectors.addToCart, this.container);
        remove(button, "loading");
      });
  }

  unload() {
    this.productForm.destroy();
    this.accordions.forEach(accordion => accordion.unload());
    this.optionButtons.destroy();
    this.quantityInput?.unload();
    this.events.forEach(unsubscribe => unsubscribe());
    this.mobileSwiper?.destroy();
    this.stickyScroll?.destroy();
    this.moreMedia?.unload();
    this.featuredProducts?.unload();
    this.variantAvailability?.unload();
  }
}

export default Product;
