import Delegate from "ftdomdelegate";
import { qs } from "@fluorescent/dom";

import { cart } from "@/glow";
import { emit } from "evx";

const selectors = {
  item: "[data-input-item]",
  quantityInput: "[data-quantity-input]",
  quantityAdd: "[data-add-quantity]",
  quantitySubtract: "[data-subtract-quantity]",
  removeItem: "[data-remove-item]",
};

export default function QuantityButtons(node) {
  const delegate = new Delegate(node);

  delegate.on("click", selectors.quantitySubtract, (_, target) => {
    const item = target.closest(selectors.item);
    const itemId = item.dataset.id;
    const qty = qs(selectors.quantityInput, item).value;
    emit("quantity-update:subtract", null, {
      itemId,
    });
    cart.updateItem(itemId, parseInt(qty) - 1);
  });

  delegate.on("click", selectors.quantityAdd, (_, target) => {
    const item = target.closest(selectors.item);
    const itemId = item.dataset.id;
    const qty = qs(selectors.quantityInput, item).value;
    emit("quantity-update:add", null, {
      itemId,
    });
    cart.updateItem(itemId, parseInt(qty) + 1);
  });

  delegate.on("click", selectors.removeItem, (_, target) => {
    const item = target.closest(selectors.item);
    const itemId = item.dataset.id;
    emit("quantity-update:remove", null, {
      itemId,
    });
    cart.updateItem(itemId, 0);
  });

  const unload = () => {
    delegate.off();
  };

  return {
    unload,
  };
}
