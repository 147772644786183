import "wicg-inert";

import { load } from "@shopify/theme-sections";
import { add, listen, qs } from "@fluorescent/dom";
import isMobile from "is-mobile";

import pageTransition from "@/lib/page-transition";
import modal from "@/lib/modal";
import storeAvailabilityDrawer from "@/lib/store-availability-drawer";
import { handleTab } from "@/lib/a11y";
import sectionClasses from "@/lib/section-classes";
import { hydrate } from "@/glow/events";
import quickViewModal from "@/lib/quick-view-modal";
import productLightbox from "@/lib/product-lightbox";

import { flashAlertModal } from "@/lib/flash-alert";
import headerOverlay from "@/lib/header-overlay";
import backToTop from "@/lib/back-to-top";

// Import sections
import "@/sections";

// Detect theme editor
if (window.Shopify.designMode === true) {
  add(document.documentElement, "theme-editor");
  document.documentElement.classList.add("theme-editor");
} else {
  const el = qs(".theme-editor-scroll-offset", document);
  el && el.parentNode.removeChild(el);
}

// Function to load all sections
const loadSections = () => {
  load("*");
  hydrate({ SelectedProductSection: null });
};

// Call above function either immediately or bind on loaded event
if (
  document.readyState === "complete" ||
  document.readyState === "interactive"
) {
  loadSections();
} else {
  listen(document, "DOMContentLoaded", loadSections);
}

if (isMobile({ tablet: true, featureDetect: true })) {
  add(document.body, "is-mobile");
}

// Page transitions
pageTransition();

// a11y tab handler
handleTab();

// Apply contrast classes
sectionClasses();

// Load productlightbox
productLightbox();

// Quick view modal
const quickViewModalElement = qs("[data-quick-view-modal]", document);

if (quickViewModalElement) {
  quickViewModal(quickViewModalElement);
}

// Setup modal
const modalElement = qs("[data-modal]", document);
modal(modalElement);

const flashModal = qs("[data-flash-alert]", document);
flashAlertModal(flashModal);

// Product availabilty drawer
const availabilityDrawer = qs("[data-store-availability-drawer]", document);
storeAvailabilityDrawer(availabilityDrawer);

// Setup header overlay
const headerOverlayContainer = document.querySelector("[data-header-overlay]");
headerOverlay(headerOverlayContainer);

// Init back to top button
backToTop();

// Make it easy to see exactly what theme version
// this is by commit SHA

window.SHA = "__gitSHA";
